import {POST, GET} from './index'

// api/Diamonds/init 初始化搜索
function init (params) {
  return POST('/api/Diamonds/init',params)
}

// api/Diamonds/list 获取钻石列表
function list (params) {
  return POST('/api/Diamonds/list',params)
}

// api/Diamonds/detail 钻石详情
function detail (params) {
  return POST('/api/Diamonds/detail',params)
}
// /api/Diamonds_Fire_Cert/GetListAsync 钻石产地
function GetListAsync (params) {
  return GET('/api/Diamonds_Fire_Cert/GetListAsync',params)
}

// api/Diamonds/PreviewPng 获取GIA、IGI。pdf转图片
function PreviewPng (params) {
  return POST('/api/Diamonds/PreviewPng',params)
}

// api/Diamonds/checkPwd 钻石初始化化查询
function checkPwd (params) {
  return POST(`/api/Diamonds/checkPwd?${params}`,params)
}

export default {
  init,
  list,
  detail,
  PreviewPng,
  checkPwd,
  GetListAsync
}
